import Link from "next/link";
import React, { ReactNode } from "react";

interface CustomLinkProps {
  children: ReactNode;
  path?: string;
  rel?: string;
  target?: string;
  url?: string;
  passHref?: boolean;
}
/**
 * CustomLink behaves like <a> or <Link> depending on whether url or path is passed
 */
export const CustomLink: React.FC<CustomLinkProps> = ({
  children,
  path,
  rel,
  target,
  url,
  passHref,
}) => {
  if ((url && path) || (!url && !path)) {
    throw new Error(
      'Please provide either "url" or "path" prop, but not both or none.'
    );
  }

  if (url) {
    return (
      <a href={url} rel={rel} target={target}>
        {children}
      </a>
    );
  }

  return (
    <Link href={path || ""} passHref={passHref}>
      <a rel={rel} target={target}>
        {children}
      </a>
    </Link>
  );
};
